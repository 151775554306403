import _ from 'lodash';


export const InventorySessionUtil = {

    getCurrStorageLocationId: (session) => {
        if (session.storageLocationId && session.storageLocationId.length > 0) {
            return session.storageLocationId;
        } else {
            return session.connectedStorageLocationId;
        }
    },

    getCurrStorageLocationName: (session) => {
        if (session.storageLocationId && session.storageLocationId.length > 0) {
            return session.storageLocation;
        } else {
            return session.connectedStorageLocation;
        }
    },

    getInventoriedBy(session, assetCode) {
        let inventoriedBy = "-";
        let scanners = session.scanners;
        _.forEach(scanners, scanner => {
            if (scanner.scannedAssets.includes(assetCode)) {
                inventoriedBy = scanner.id;
            }
        });
        return inventoriedBy;
    }
}