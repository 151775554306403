<template>
	<b-modal id="cancel-inventory-session" title="Cancel Inventory Session" ref="modal" ok-title="Save" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to
			<b>cancel</b> this session <b class="numFont">{{ selInventorySession.sessionId }}</b>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import sessionAPI from '@/api/inventorySessionApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'cancel-inventory-session',
	components: {
		Loading,
	},
	data() {
		return {
			selInventorySession: {},

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelInventorySession', (param) => {
			this.selInventorySession = param;
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			this.selInventorySession['status'] = 'Cancelled';
			this.selInventorySession['dateCancelled'] =
				DateUtil.getCurrentTimestamp();
			this.selInventorySession['cancelledBy'] = this.loggedUser.id;

			await this.cancelInventorySession();
		},
		async cancelInventorySession() {
			let sessionId = this.selInventorySession.sessionId;
			try {
				let { data } = await sessionAPI.cancelInventorySession(
					this.selInventorySession,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveSession', data.inventorySession);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error cancelling Inventory Session "${sessionId}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error cancelling Inventory Session "${sessionId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			// do nothing
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelInventorySession');
	},
};
</script>