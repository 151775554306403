<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" @click.stop="row.toggleDetails"
			class="mr-1 mt-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>

		<b-button v-show="(row.item.status === 'Draft' || row.item.status === 'On-Going') && !isViewer" size="sm"
			v-b-modal.edit-inventory-session v-b-tooltip.hover.top="'Edit Details'" variant="warning"
			@click.stop="updateSelInventorySession(row.item)" class="mr-1 mt-1">
			<i class="fa fa-pencil"></i>
		</b-button>

		<b-button v-show="isAllowedToCancel(row.item)" size="sm" v-b-modal.cancel-inventory-session
			v-b-tooltip.hover.top="'Cancel Inventory Session'" variant="danger"
			@click.stop="updateSelInventorySession(row.item)" class="mr-1 mt-1">
			<i class="fa fa-ban"></i>
		</b-button>

		<b-button v-show="isAllowedToMarkAsDone(row.item)" size="sm" variant="success"
			v-b-modal.mark-as-done-inventory-session v-b-tooltip.hover.top="'Mark Inventory Session as Done'"
			class="mr-1 mt-1" @click.stop="updateSelInventorySession(row.item)">
			<i class="fa fa-check"></i>
		</b-button>
	</div>
</template>

<script>
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'inventory-row-actions',
	props: {
		row: {
			type: Object,
			required: true
		},
		isSuperAdmin: {
			type: Boolean,
			required: true
		},
		isViewer: {
			type: Boolean,
			required: true
		},
		params: {
			type: Object,
			required: true
		}
	},
	methods: {
		updateSelInventorySession(item) {
			this.$store.commit('SET_CURR_INVENTORY_SESSION', item);
			EventBus.$emit('onUpdateSelInventorySession', item);
		},
		isAllowedToCancel(session) {
			return (session.status == 'Draft' || session.status == 'On-Going') && !this.isViewer;
		},
		isAllowedToMarkAsDone(session) {
			return session.status == 'On-Going' && _.size(session.scannedAssets) > 0 && !this.isViewer;
		}
	}
};
</script>

