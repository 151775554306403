<template>
	<b-modal id="edit-inventory-session" title="Edit Inventory Session" size="lg" ref="modal" ok-title="Update"
		@ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col sm="8">
						<b>SESSION INFORMATION</b>
					</b-col>
				</b-row>
				<b-row class="my-12">
					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Company:">
							<b-form-input id="Company" name="Company" type="text" v-model="selCompany.name" readonly />
						</b-form-group>
					</b-col>

					<b-col lg="6" md="12" sm="12">
						<b-form-group label="Storage Location">
							<b-form-input id="Storage Location" name="Storage Location" type="text"
								v-model="selStorageLocation.name" readonly />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="my-12">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Description:" label-for="description" description>
							<b-form-textarea name="Description" type="text" v-model="form.description" maxlength="200"
								v-validate="{
									required: true,
									regex: remarksRegex
								}" :rows="3" placeholder="Description" />
							<span v-show="errors.has('Description')" class="help-block">
								{{ errors.first('Description') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="my-2">
					<b-col sm="8">
						<b>LIST OF SCANNERS</b>
					</b-col>
				</b-row>
				<b-row no-gutters class="my-12">
					<b-col sm="6" class="mr-4">
						<b-form-group label="Select Scanners" label-for="scanners"
							description="The person you want to include to scan in this inventory session">
							<v-select name="Scanner" class="style-chooser" label="text" :options="userOptions"
								:reduce="(user) => user.value" v-model="selScanner">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a user
									</em>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="4" sm="4">
						<b-button variant="primary" class="add-button" @click="addScanner">
							Add Scanner
						</b-button>
					</b-col>
				</b-row>

				<div>
					<b-row class="mt-4 mb-2">
						<b-col sm="8" class="md-left-text-sm-right">
							<b>SELECTED SCANNERS</b>
						</b-col>
					</b-row>

					<b-row class="mb-2" v-show="form.scanners.length > 0">
						<b-col sm="3" class="text-sm-left">
							<b>Email</b>
						</b-col>
						<b-col sm="3" class="text-sm-left">
							<b>Name</b>
						</b-col>
						<b-col sm="1" class="text-sm-left">
							<b>Action</b>
						</b-col>
					</b-row>
					<b-row class="mb-2" v-bind:key="scanner.id" v-for="scanner in form.scanners">
						<b-col sm="3" class="text-sm-left">
							{{ scanner.id }}
						</b-col>
						<b-col sm="3" class="text-sm-left">
							{{ scanner.name }}
						</b-col>
						<b-col sm="1" class="text-sm-left">
							<b-button size="sm" v-b-tooltip.hover.top="'Remove Scanner'" variant="danger"
								@click.stop="removeScanner(scanner.id)" class="mr-1">
								<i class="fa fa-trash"></i>
							</b-button>
						</b-col>
					</b-row>

					<b-row class="mb-2" v-show="form.scanners.length === 0">
						<b-col sm="6" class="text-sm-left">
							<i>There is no selected scanners yet.</i>
						</b-col>
					</b-row>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import sessionAPI from '@/api/inventorySessionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';


export default {
	name: 'edit-inventory-session',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		allCompanyOptions: {
			type: Array,
			required: true,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
		allUserOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			form: {
				sessionId: '',
				status: '',
				description: '',

				company: '',
				companyId: '',
				storageLocation: '',
				storageLocationId: '',
				connectedCompany: '',
				connectedCompanyId: '',
				connectedStorageLocation: '',
				connectedStorageLocationId: '',

				scanners: [],
				allowedScanners: [],
				inputAssetLog: config.inputAssetLogDefaultValue,

				scannedAssets: [],
				inTransitAssets: [],

				dateCreated: null,
				createdBy: '',
				dateUpdated: null,
				updatedBy: '',
				dateCancelled: null,
				cancelledBy: '',
			},

			companyOptions: [],
			storageLocationOptions: [],
			userOptions: [],

			selCompany: config.companyDefaultValue,
			selStorageLocation: config.storageLocationDefaultValue,
			selScanner: config.userDefaultValue,
			selScanners: {},

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompanyOptions: function () {
			this.companyOptions = this.allCompanyOptions;
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});
			this.userOptions.push({
				value: config.userDefaultValue,
				text: ' - Please select - ',
			});
		},
		selCompany: function () {
			if (this.selCompany.id) {
				this.retrieveStorageLocation();
				this.retrieveUsers();
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		}
	},
	mounted() {
		EventBus.$on('onUpdateSelInventorySession', (param) => {
			this.selInventorySession = param;
		});
	},
	methods: {
		retrieveStorageLocation() {
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});
			this.allStorageLocationOptions.forEach((loc) => {
				if (loc.value.companyId === this.selCompany.id) {
					this.storageLocationOptions.push(loc);
				}
			});
		},
		retrieveUsers() {
			this.userOptions = [];

			this.userOptions.push({
				value: config.userDefaultValue,
				text: ' - Please select - ',
			});

			this.allUserOptions.forEach((user) => {
				if (user.value.companyId === this.selCompany.id) {
					this.userOptions.push(user);
				}
			});

			this.selScanner = config.userDefaultValue;
		},
		isExistingScanner(userId) {
			return ValidationUtil.arrayHasValueDirectField(
				this.form.scanners,
				'id',
				userId
			);
		},
		addScanner() {
			if (this.selScanner.id === null) {
				this.$toaster.warning('Please select a scanner to add.');
				return;
			}

			if (this.isExistingScanner(this.selScanner.id)) {
				let scannerName = this.selScanner.name;
				this.$toaster.warning(`Scanner "${scannerName}" already added.`);
				return;
			}

			let scanner = { ...this.selScanner };
			scanner['scannedAssets'] = [];
			scanner['isDone'] = 'false';
			this.form.scanners.push(scanner);
		},
		removeScanner(scannerId) {
			let index = _.findIndex(this.form.scanners, o => {
				return o.id === scannerId;
			});

			if (index < 0) {
				this.$toaster.warning(`Scanner "${scannerId}" does not exists.`);
				return;
			}

			let scanner = this.form.scanners[index];
			if (scanner.scannedAssets.length > 0) {
				this.$toaster.warning(`Removing Scanner "${scannerId}" is not allowed since he/she already have scanned assets. `);
				return;
			}

			// delete scanner
			this.form.scanners.splice(index, 1);
			delete this.selScanners[scannerId];
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			} else if (this.form.scanners.length === 0) {
				this.$toaster.warning('At least 1 selected scanner is required.');
				return;
			}

			await this.handleSubmit();
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// Update form fields
			this.updateCompanyAndLocation();
			this.updateAllowedScanners();
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			let sessionId = this.form.sessionId;
			try {
				let { data } = await sessionAPI.updateInventorySession(
					this.form,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveSession', data.inventorySession);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error updating Inventory Session "${sessionId}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error updating Inventory Session "${sessionId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateCompanyAndLocation() {
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
			this.form.storageLocation = this.selStorageLocation.name;
			this.form.storageLocationId = this.selStorageLocation.id;
		},
		updateAllowedScanners() {
			this.form.allowedScanners = _.map(this.form.scanners, 'id');
		},

		onReset() {
			/* Reset our form values */
			let session = this.$store.getters.currInventorySession;
			this.form.id = session.id;
			this.form.sessionId = session.sessionId;
			this.form.status = session.status;
			this.form.description = session.description;
			this.form.company = session.company;
			this.form.companyId = session.companyId;
			this.form.storageLocation = session.storageLocation;
			this.form.storageLocationId = session.storageLocationId;
			this.form.connectedCompany = session.connectedCompany;
			this.form.connectedCompanyId = session.connectedCompanyId;
			this.form.connectedStorageLocation = session.connectedStorageLocation;
			this.form.connectedStorageLocationId = session.connectedStorageLocationId;

			this.form.scanners = [...session.scanners];
			this.form.allowedScanners = session.allowedScanners;
			this.form.inputAssetLog = session.inputAssetLog;

			this.form.scannedAssets = session.scannedAssets;
			this.form.inTransitAssets = session.inTransitAssets;

			this.form.dateCreated = session.dateCreated;
			this.form.createdBy = session.createdBy;
			this.form.dateUpdated = session.dateUpdated;
			this.form.updatedBy = session.updatedBy;
			this.form.dateCancelled = session.dateCancelled;
			this.form.cancelledBy = session.cancelledBy;

			let company = this.allCompaniesObj[session.companyId];
			this.selCompany = DropDownItemsUtil.getCompanyItem(company);

			let locId = this.getCurrStorageLocationId(session);
			let storageLocation = this.allStorageLocationsObj[locId];
			this.selStorageLocation =
				DropDownItemsUtil.getStorageLocationItem(storageLocation);

			this.selScanner = config.userDefaultValue;
			this.selScanners = {};

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		getCurrStorageLocationId: (inventorySession) => {
			if (
				inventorySession.storageLocationId &&
				inventorySession.storageLocationId.length > 0
			) {
				return inventorySession.storageLocationId;
			} else {
				return inventorySession.connectedStorageLocationId;
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelInventorySession');
	},
};
</script>