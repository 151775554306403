<template>
	<b-modal id="mark-as-done-inventory-session" title="Mark Inventory Session" ref="modal" ok-title="Mark As Done"
		@ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to
			<b>mark as done</b> this session <b class="numFont">{{ selInventorySession.sessionId }}</b>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { AssetUtil } from '@/utils/assetUtil';
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { DateUtil } from '@/utils/dateutil';
import { InventorySessionUtil } from '@/utils/inventorySessionUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & Database
import sessionAPI from '@/api/inventorySessionApi';
import assetDAO from '@/database/assets';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';


export default {
	name: 'mark-as-done-inventory-session',
	components: {
		Loading,
	},
	props: {
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
		allAssetTypeCodesObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			selInventorySession: {},
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelInventorySession', (param) => {
			this.selInventorySession = param;
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let assets = await this.getAssets(this.selInventorySession);
			await this.markAsDone(this.selInventorySession, assets);
		},
		createAsset(assetCode, session) {
			let assetObj = {};

			if (ValidationUtil.isValidAssetCodeDefault(assetCode)) {
				// Create Asset from Default Asset Type
				let endIndex = assetCode.length - 6;
				let prefix = assetCode.substr(0, endIndex);
				let assetTypeCodeObj = AssetUtil.getAssetTypeCodeByPrefix(prefix, Object.values(this.allAssetTypeCodesObj));

				assetObj = AssetUtil.createAsset(assetCode, this.currUserId, assetTypeCodeObj);
			} else {
				// Create Asset from Custom Asset Type
				let assetTypeObj = AssetTypeUtil.getAssetType(this.allAssetTypesObj, assetCode);

				if (!_.isEmpty(assetTypeObj)) {
					assetObj = AssetUtil.createCustomAsset(assetCode, this.currUserId, assetTypeObj);
				}
			}

			assetObj.currCompany = session.company;
			assetObj.currCompanyId = session.companyId;

			return assetObj;
		},
		async getAssets(session) {
			let assetsObj = {};

			let assetsObjResult = await assetDAO.getAssetsByCode(session.scannedAssets);
			assetsObj = assetsObjResult[0];

			_.forEach(session.scannedAssets, assetCode => {
				let assetObj = assetsObj[assetCode];

				if (!assetObj || _.isEmpty(assetObj)) {
					assetObj = this.createAsset(assetCode, session);
				}

				// B. Update Asset Details
				let details = assetObj.details;
				details.storageLocation = InventorySessionUtil.getCurrStorageLocationName(session);
				details.storageLocationId = InventorySessionUtil.getCurrStorageLocationId(session);
				details.inventorySessionId = session.sessionId;
				details.lastInventoryDate = session.dateUpdated;
				details.inventoriedBy = session.updatedBy;

				assetsObj[assetCode] = assetObj;
			});

			return Object.values(assetsObj);
		},
		async markAsDone(inventorySession, assets) {
			let sessionId = this.selInventorySession.sessionId;
			try {
				let { data } = await sessionAPI.markInventorySessionAsDone(
					inventorySession,
					assets,
					this.currUserId,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveSession', data.inventorySession);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error marking as done Inventory Session "${sessionId}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error marking as done Inventory Session "${sessionId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			// do nothing
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelInventorySession');
	},
};
</script>